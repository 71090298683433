// ** React Imports
// import { Link } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/avatar";
import { useUser } from "@hooks/useUser";
// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power,
  Info,
} from "react-feather";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { currentUser, getToken, logout } from "../../../../auth";
// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/no-profile.png";
import { useEffect, useState } from "react";

export function useEffectAsync(effect, inputs) {
  useEffect(() => {
    effect();
  }, inputs);
}

const UserDropdown = ({ ...props }) => {
  const user = currentUser();
  const { reactDom } = props;
  const { useNavigate, Link } = reactDom || {};

  const navigator = useNavigate();

  const [avatar, setAvatar] = useState(defaultAvatar);
  const [token, setToken] = useState();
  const { getUserToken } = useUser();
  useEffectAsync(async () => {
    let result = await getToken();
    await getUserToken();
    setToken(result);
  }, []);

  useEffect(() => {
    if (token) {
      fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const urlCreator = window.URL;
          const imageUrl = urlCreator.createObjectURL(blob);
          setAvatar(imageUrl);
        })
        .catch((err) => {
          console.error(err);
          setAvatar(defaultAvatar);
        });
    }
  }, [token]);

  // console.log("User dropdown", user)
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none ">
          <span className="user-name fw-bold text-primary">
            {user[0]?.name}
          </span>
          <span className="user-status">{user[0]?.username}</span>
        </div>
        <Avatar
          img={avatar || defaultAvatar}
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <User size={14} className="me-75" />
          <span className="align-middle">Profile</span>
        </DropdownItem> */}
        <DropdownItem
          tag={Link}
          to="/my-profile"
          onClick={(e) => {
            e.preventDefault();
            navigator("/my-profile");
          }}
        >
          <Info size={14} className="me-75" />
          <span className="align-middle">My Profile</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CheckSquare size={14} className="me-75" />
          <span className="align-middle">Tasks</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <MessageSquare size={14} className="me-75" />
          <span className="align-middle">Chats</span>
        </DropdownItem> */}
        <DropdownItem divider />
        {/* <DropdownItem
          tag={Link}
          to="/pages/"
          onClick={(e) => e.preventDefault()}
        >
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CreditCard size={14} className="me-75" />
          <span className="align-middle">Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <HelpCircle size={14} className="me-75" />
          <span className="align-middle">FAQ</span>
        </DropdownItem> */}
        <DropdownItem onClick={() => logout()}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
