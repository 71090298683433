// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context

import { themeConfig, ThemeContext, Spinner } from "@app/layout";

// ** ThemeConfig

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
// // ** Core styles

import "./assets/scss/style.scss";

// ** Lazy load app
const LazyApp = lazy(() => import("./router/Router"));

import React from "react";
import ErrorBoundary from "./components/ErrorBoundary";

function RootApp() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <ThemeContext>
              <LazyApp />
              <Toaster
                position={themeConfig.layout.toastPosition}
                toastOptions={{ className: "react-hot-toast" }}
              />
            </ThemeContext>
          </Suspense>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default RootApp;
