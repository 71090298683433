// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.misc-wrapper .misc-inner {
  position: relative;
  max-width: 750px;
}
.misc-wrapper .brand-logo {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0;
}
.misc-wrapper .brand-logo .brand-text {
  font-weight: 600;
}

@media (max-height: 625px) {
  .misc-wrapper .misc-inner {
    margin-top: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/@core/scss/base/pages/page-misc.scss"],"names":[],"mappings":"AAWA;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAVF;AAYE;EACE,kBAAA;EACA,gBAZmB;AAEvB;AAaE;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,SAAA;AAXJ;AAYI;EACE,gBAAA;AAVN;;AAeA;EAEI;IACE,gBAAA;EAbJ;AACF","sourcesContent":["// ================================================================================================\n//     File Name: page-misc.scss\n//     Description: Coming Soon pages custom scss\n//     ----------------------------------------------------------------------------------------------\n//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template\n//     Author: PIXINVENT\n//     Author URL: http://www.themeforest.net/user/pixinvent\n// ================================================================================================\n\n$misc-inner-max-width: 750px;\n\n.misc-wrapper {\n  display: flex;\n  flex-basis: 100%;\n  min-height: 100vh;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n\n  .misc-inner {\n    position: relative;\n    max-width: $misc-inner-max-width;\n  }\n\n  .brand-logo {\n    display: flex;\n    justify-content: center;\n    position: absolute;\n    top: 2rem;\n    left: 2rem;\n    margin: 0;\n    .brand-text {\n      font-weight: 600;\n    }\n  }\n}\n\n@media (max-height: 625px) {\n  .misc-wrapper {\n    .misc-inner {\n      margin-top: 4rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
