// auth.js

import { PublicClientApplication } from "@azure/msal-browser";
import { useUser } from "./utility/hooks/useUser";

export const msalConfig = {
  auth: {
    clientId: "f8970b73-4e1a-4c94-84b3-49561df99eec", // Replace with your Azure AD client ID
    // clientId: 'ae03126e-e674-4bc6-8827-bb2a2b8a06bf', // Replace with your Azure AD client ID
    authority:
      "https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2", // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
    // authority: 'https://login.microsoftonline.com/68129764-41d7-4ea3-bbdf-258b49b3eb9b', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
    redirectUri: window.location.origin, // The URL where Azure AD will redirect after authentication
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const login = async () => {
  try {
    var account = currentUser();
    if (account) return account;
    // console.log(account)
    const loginResponse = await msalInstance.loginRedirect(silentRequest);
    return loginResponse.account;
  } catch (error) {
    console.error("Error during login:", error);
    return null;
  }
};
export const currentUser = () => {
  return msalInstance.getAllAccounts();
};
const silentRequest = {
  scopes: ["openid", "profile", "email", "user.read"], // Add any required scopes for your application
};
export const getToken = async () => {
  try {
    let user = currentUser()[0];
    msalInstance.setActiveAccount(user);
    const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
    return tokenResponse.accessToken;
  } catch (error) {
    // If silent token acquisition fails, use redirect instead of popup
    console.error('Silent token acquisition failed:', error);
    try {
      // Initiate redirect-based token acquisition
      await msalInstance.acquireTokenRedirect(silentRequest);
      // This function will redirect the page, so we won't reach the code below
      // The token will be handled in the redirect callback
      return null;
    } catch (error) {
      console.error("Error during token acquisition:", error);
      return null;
    }
  }
};

export const logout = () => {
  msalInstance.logoutPopup();
};
msalInstance
  .handleRedirectPromise()
  .then((res) => {
    console.log(res);
  })
  .catch((err) => {
    console.error(err);
  });
