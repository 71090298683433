import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import jwtDecode from "jwt-decode";
import { Client } from "@microsoft/microsoft-graph-client";
const iamApiUrl = process.env.REACT_APP_API_URL;

const msalConfig = {
  auth: {
    clientId: "f8970b73-4e1a-4c94-84b3-49561df99eec", // Replace with your Azure AD client ID
    // clientId: 'ae03126e-e674-4bc6-8827-bb2a2b8a06bf', // Replace with your Azure AD client ID
    // authority: 'https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2', // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
    authority:
      "https://login.microsoftonline.com/1cec92f6-5859-4f6a-b76e-9f14529924a2", // Replace with your Azure AD authority URL (e.g., 'https://login.microsoftonline.com/your-tenant-id')
    redirectUri: window.location.origin, // The URL where Azure AD will redirect after authentication
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const accessTokenRequest = (user) => ({
  // scopes: ['openid', 'profile', 'email','user.read'],
  // scopes: ["User.Read","api://f8970b73-4e1a-4c94-84b3-49561df99eec"],
  scopes: [
    // "api://f8970b73-4e1a-4c94-84b3-49561df99eec",
    "api://f8970b73-4e1a-4c94-84b3-49561df99eec/Refund.Admins",
    "api://f8970b73-4e1a-4c94-84b3-49561df99eec/Refund.Readers",
    // "api://f8970b73-4e1a-4c94-84b3-49561df99eec/.default"
  ],
  account: user,
  storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
});

const graphApiAccessTokenRequest = (user) => ({
  scopes: ["https://graph.microsoft.com/User.Read"],
  account: user,
  storeAuthStateInCookie: false,
});

// Add logging utility
const logTokenEvent = (event, details = '') => {
  console.log(`[Token Manager] ${event}${details ? ': ' + details : ''}`);
};

// Add these utility functions at the top level
let isRefreshing = false;
let waitingRequests = [];

const addRequestToQueue = () => {
  logTokenEvent('Adding request to queue', `Queue size: ${waitingRequests.length}`);
  return new Promise((resolve) => {
    waitingRequests.push(resolve);
  });
};

const resolveWaitingRequests = (token) => {
  logTokenEvent('Resolving waiting requests', `Count: ${waitingRequests.length}`);
  waitingRequests.forEach(resolve => resolve(token));
  waitingRequests = [];
};

// Add this helper function at the top of the file
const isValidToken = (token) => {
  return token && token !== "undefined" && token !== "null" && token.length > 0;
};

export const useUser = () => {
  // debugger
  // const { instance, inProgress, accounts } = useMsal();
  const msalInstance = new PublicClientApplication(msalConfig);
  let user = msalInstance.getAllAccounts()[0];
  // const [msalInstance] = useState(
  //   () => new PublicClientApplication(msalConfig)
  // );
  // const [user] = useState(msalInstance.getAllAccounts()[0]);

  // useEffect(async () => {

  //     let result = await getToken();
  //     console.log(result)
  // }, [])

  const getToken = async () => {
    logTokenEvent('Getting MSAL token');
    let response = await msalInstance
      .acquireTokenSilent(accessTokenRequest(user))
      .catch((err) => {
        console.error(err);
        return undefined;
      });

    return response.accessToken;
  };

  const getUserToken = async () => {
    logTokenEvent('Get user token started');
    
    if (isRefreshing) {
      logTokenEvent('Refresh in progress', 'Waiting for completion');
      return await addRequestToQueue();
    }

    const currentToken = localStorage.getItem("userAccessToken");
    
    if (currentToken) {
      try {
        const decodedToken = jwtDecode(currentToken);
        const isExpired = decodedToken.exp < (Date.now() / 1000);
        
        logTokenEvent('Token status', `Expired: ${isExpired}, Exp: ${new Date(decodedToken.exp * 1000).toISOString()}`);
        
        if (!isExpired) {
          logTokenEvent('Using existing valid token');
          return currentToken;
        }

        logTokenEvent('Token expired, starting refresh');
        isRefreshing = true;
        
        try {
          const refreshResult = await refreshToken(currentToken);
          if (refreshResult) {
            logTokenEvent('Token refresh successful');
            const { token, refreshToken: newRefreshToken } = refreshResult;
            localStorage.setItem("userAccessToken", token);
            localStorage.setItem("userRefreshToken", newRefreshToken);
            resolveWaitingRequests(token);
            return token;
          }
          logTokenEvent('Token refresh failed');
        } finally {
          logTokenEvent('Releasing refresh lock');
          isRefreshing = false;
        }
      } catch (error) {
        logTokenEvent('Token validation/refresh error', error.message);
      }
    }

    // Get new token flow
    try {
      logTokenEvent('Getting new MSAL token');
      const msalToken = await getToken();
      if (!msalToken) {
        logTokenEvent('Failed to get MSAL token');
        throw new Error('Failed to get MSAL token');
      }

      logTokenEvent('Exchanging MSAL token for user token');
      const response = await fetch(`${iamApiUrl}/users/token`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${msalToken}`,
        },
      });


      if (!response.ok) {
        logTokenEvent('Token exchange failed', `Status: ${response.status}`);
        throw new Error(`Failed to get user token: ${response.status}`);
      }

      const data = await response.json();
      
      logTokenEvent('Successfully obtained new token');
      localStorage.setItem("userAccessToken", data.token);
      localStorage.setItem("userRefreshToken", data.refreshToken);
      return data.token;
    } catch (error) {
      logTokenEvent('Error getting new token', error.message);
      localStorage.removeItem("userAccessToken");
      localStorage.removeItem("userRefreshToken");
      return null;
    }
  };

  // Update refreshToken with logging
  const refreshToken = async (token) => {
    logTokenEvent('Starting token refresh');
    try {
      const response = await fetch(`${iamApiUrl}/auth/users/refresh-token`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: localStorage.getItem("userRefreshToken"),
        }),
      });

      if (!response.ok) {
        logTokenEvent('Refresh request failed', `Status: ${response.status}`);
        throw new Error(`Refresh token failed: ${response.status}`);
      }

      logTokenEvent('Refresh request successful');
      return await response.json();
    } catch (error) {
      logTokenEvent('Refresh token error', error.message);
      localStorage.removeItem("userAccessToken");
      localStorage.removeItem("userRefreshToken");
      return null;
    }
  };

  const getUser = () => {
    const token = localStorage.getItem("userAccessToken");
    if (!isValidToken(token)) {
      return null;
    }
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  const canAccessApplication = (appName) => {
    const token = localStorage.getItem("userAccessToken");
    if (!isValidToken(token)) {
      return false;
    }
    try {
      const currentUser = jwtDecode(token);
      if (!currentUser.applications) {
        return false;
      }
      if (!Array.isArray(currentUser.applications)) {
        return appName.toLowerCase() === currentUser.applications.toLowerCase();
      }
      if (currentUser.applications.length === 0) {
        return false;
      }
      return currentUser.applications
        .map((r) => r.toLowerCase())
        .some((r) => r === appName.toLowerCase());
    } catch (error) {
      console.error('Error checking application access:', error);
      return false;
    }
  };

  const hasPermission = (permissions) => {
    const user = getUser();
    if (!user || !user.permission || user.permission.length === 0) return false;
    if (!permissions) return true;
    let permissionsArr = permissions.toLowerCase().split(",");
    if (permissionsArr.length === 0) return true;
    
    if(!Array.isArray(user.permission)){
      return permissionsArr.some(r => user.permission.toLowerCase() === r);
    }

    return permissionsArr.some(r => user.permission.map(p => p.toLowerCase()).includes(r));
  };

  const isInRole = (role) => {
    const roleKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    if (!role) return true;
    const user = getUser();
    if (!user || !user[roleKey]) return false;
    
    if (!Array.isArray(user[roleKey])) {
      return role.toLowerCase() === user[roleKey].toLowerCase();
    }

    return user[roleKey].map(r => r.toLowerCase()).includes(role.toLowerCase());
  };

  const getGraphApiToken = async () => {
    let response = await msalInstance
      .acquireTokenSilent(graphApiAccessTokenRequest(user))
      .catch((err) => {
        console.error(err);
        return undefined;
      });

    return response.accessToken;
  };

  const fetchGraphClient = async () => {
    const token = await getGraphApiToken();
    return Client.init({
      authProvider: (done) => {
        done(null, token);
      },
    });
  };

  const fetchUserProfile = async () => {
    try {
      const graphClient = await fetchGraphClient();
      const profile = await graphClient
        .api("/me")
        .select(
          "displayName,jobTitle,mail,userPrincipalName,department,mobilePhone"
        )
        .get();
      return profile;
    } catch (err) {
      console.error("Error fetching user profile:", err);
      return null;
    }
  };

  const fetchUserPhoto = async () => {
    try {
      const graphClient = await fetchGraphClient();
      const responseBlob = await graphClient
        .api("/me/photo/$value")
        .responseType("blob")
        .get();
      return responseBlob;
    } catch (err) {
      console.error("Error fetching user photo:", err);
      return null;
    }
  };

  return {
    user,
    getUser,
    getToken,
    canAccessApplication,
    hasPermission,
    isInRole,
    getUserToken,
    getGraphApiToken,
    fetchUserProfile,
    fetchUserPhoto,
  };
};
//
