// ** React Imports

import { useContext, Suspense } from "react";

// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can";

// ** Spinner Import
import Spinner from "../spinner/Loading-spinner";
import { useUser } from "@src/utility/hooks/useUser";

const PrivateRoute = ({ children, route, reactDom }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext);
  const { hasPermission } = useUser();
  const { Navigate, useNavigate } = reactDom;
  const navigate = useNavigate();
  if (route) {
    let action = null;
    let resource = null;
    let restrictedRoute = false;
    let permissions = "";

    if (route.meta) {
      permissions = route.meta.permissions;
      action = route.meta.action;
      resource = route.meta.resource;
      restrictedRoute = route.meta.restricted;
    }
    // if (!user) {
    //   navigate("/login");
    // }
    // if (user && restrictedRoute) {
    //   navigate("/");
    // }
    // if (user && restrictedRoute && user.role === "client") {
    //   navigate("/access-control");
    // }
    // if (user && !hasPermission(action || "read", resource)) {
    //   navigate("/misc/not-authorized");
    // }
    if (permissions &&!hasPermission(permissions)) {
      return <Navigate to={"/forbidden"} />;
    }
  }

  return (
    <Suspense fallback={<Spinner className="content-loader" />}>
      {children}
    </Suspense>
  );
};

export default PrivateRoute;
