/* eslint-disable implicit-arrow-linebreak */
// ** React Imports
import { useContext } from "react";
import { AbilityContext } from "@src/utility/context/Can";
import { useUser } from "@src/utility/hooks/useUser";

/**
 * Return which component to render based on it's data/context
 * @param {Object} item nav menu item
 */
export const resolveVerticalNavMenuItemComponent = (item) => {
  if (item.header) return "VerticalNavMenuSectionHeader";
  if (item.children) return "VerticalNavMenuGroup";
  return "VerticalNavMenuLink";
};

/**
 * Return which component to render based on it's data/context
 * @param {Object} item nav menu item
 */
export const resolveHorizontalNavMenuItemComponent = (item) => {
  if (item.children) return "HorizontalNavMenuGroup";
  return "HorizontalNavMenuLink";
};

/**
 * Check if nav-link is active
 * @param {Object} link nav-link object
 */
export const isNavLinkActive = (link, currentURL, routerProps) => {
  return (
    currentURL === link ||
    (routerProps &&
      routerProps.meta &&
      routerProps.meta.navLink &&
      routerProps.meta.navLink === link)
  );
  // return currentURL === link
};

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param activeItem
 */
export const hasActiveChild = (item, currentUrl) => {
  const { children } = item;

  if (!children) {
    return false;
  }

  for (const child of children) {
    if (child.children) {
      if (hasActiveChild(child, currentUrl)) {
        return true;
      }
    }

    // Check if the child has a link and is active
    if (
      child &&
      child.navLink &&
      currentUrl &&
      (child.navLink === currentUrl || currentUrl.includes(child.navLink))
    ) {
      return true;
    }
  }

  return false;
};

/**
 * Check if this is a children
 * of the given item
 *
 * @param children
 * @param openGroup
 * @param currentActiveGroup
 */
export const removeChildren = (children, openGroup, currentActiveGroup) => {
  children.forEach((child) => {
    if (!currentActiveGroup.includes(child.id)) {
      const index = openGroup.indexOf(child.id);
      if (index > -1) openGroup.splice(index, 1);
      if (child.children)
        removeChildren(child.children, openGroup, currentActiveGroup);
    }
  });
};

const checkForVisibleChild = (arr) => {
  return arr.some((i) => {
    if (i.children) {
      return checkForVisibleChild(i.children);
    } else {
      return canViewMenuItem(i);
    }
  });
};

// export const canViewMenuGroup = (item) => {
//   const user = useUser();
//   // ! This same logic is used in canViewHorizontalNavMenuGroup and canViewHorizontalNavMenuHeaderGroup. So make sure to update logic in them as well
//   const hasAnyVisibleChild =
//     item.children && checkForVisibleChild(item.children);

//   // ** If permissions is defined in item => Return based on children visibility (Hide group if no child is visible)
//   // ** Else check permissions along with checking if has any visible child
//   if (!item.permissions) {
//     return hasAnyVisibleChild;
//   }
//   return user.hasPermission(item.permissions) && hasAnyVisibleChild;
// };

// export const canViewMenuItem = (item) => {
//   // const ability = useContext(AbilityContext);
//   const user = useUser();
//   if (!item.permissions || item) return true;
//   return user.hasPermission(item.permissions);

// };

export const canViewMenuGroup = (item) => {
  const user = useUser();
  const hasAnyVisibleChild =
    item.children && checkForVisibleChild(item.children);

  if (!item.permissions && !item.roles) {
    return hasAnyVisibleChild;
  }

  const hasPermission =
    item.permissions ? user.hasPermission(item.permissions) : true;

  const hasRole =
    item.roles && Array.isArray(item.roles)
      ? item.roles.some((role) => user.isInRole(role))
      : user.isInRole(item.roles);

  return hasAnyVisibleChild && hasPermission && hasRole;
};

export const canViewMenuItem = (item) => {
  const user = useUser();

  if (!item.permissions && !item.roles) return true;

  const hasPermission =
    item.permissions ? user.hasPermission(item.permissions) : true;

  const hasRole =
    item.roles && Array.isArray(item.roles)
      ? item.roles.some((role) => user.isInRole(role))
      : user.isInRole(item.roles);

  return hasPermission && hasRole;
};